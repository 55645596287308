export const blogs = {
  All: {
    Cloud: [
      {
        type: "featured",
        name: "Implementation of The 12 Factors App Methodology with AWS Cloud for a SAAS Product - Part 1",
        image: "https://media.licdn.com/dms/image/D5612AQGKMZJszcAf5A/article-cover_image-shrink_423_752/0/1664560274887?e=1703116800&v=beta&t=l3l0oYTjVtKZhpGQGvsCV_n2SiXI84DOpdnKXP4mc6U",
        description: "The Twelve-Factor App principles, written by Adam Wiggins, co-founder of Heroku, are listed below that are should be implemented while building a #saas product. Let’s discuss here every factor in short step by step and communicate with the related AWS services that serve The Twelve-Factor App methodology's purposes actually. But in this article I am discussing about only 3 factors as codebase, dependencies and config.",
        date: "Oct 01, 2022",
        source: "https://www.linkedin.com/pulse/implementation-12-factors-app-methodology-aws-cloud-saas-al-mahmud/",
        tools: ["AWS", "Cloud", "DevOps", "Serverless"],
      },
      {
        type: "featured",
        name: "Implementation of The 12 Factors App Methodology with AWS Cloud for a SAAS Product - Part 2",
        image: "https://media.licdn.com/dms/image/D5612AQEerb0jDR1Q-g/article-cover_image-shrink_423_752/0/1664560389913?e=1703116800&v=beta&t=tzpJ9ahbumpe_W-kahar0STUKXCEM9KKDXiMAgvPRtE",
        description: "The Twelve-Factor App principles, written by Adam Wiggins, co-founder of Heroku, are listed below that are should be implemented while building a #saas product. Let’s discuss here five factors as backing services, build-release-run, processes, port binding, concurrency in short step by step and communicate with the related AWS services that serve The Twelve-Factor App methodology's purposes actually.",
        date: "Oct 01, 2022",
        source: "https://www.linkedin.com/pulse/implementation-12-factors-app-methodology-aws-cloud-saas-al-mahmud-1c/",
        tools: ["CI/CD", "Caching", "Lambda", "Concurrency"],
      },
      {
        type: "featured",
        name: "Implementation of The 12 Factors App Methodology with AWS Cloud for a SAAS Product - Part 3",
        image: "https://media.licdn.com/dms/image/D5612AQG73nXRyYMQLg/article-cover_image-shrink_423_752/0/1664560365902?e=1703116800&v=beta&t=wqkxJP4xBbcgsM1fpNJk5KvXR0Ew_kVUciCi_tlaq5Q",
        description: "The Twelve-Factor App principles, written by Adam Wiggins, co-founder of Heroku, are listed below that are should be implemented while building a #saas product and scale seemlessly. Let’s discuss the last 4 factors as disposability, dev/prod parity, logs and admin processes shortly and communicate with the related AWS services that serve The Twelve-Factor App methodology's purposes and maintain the standardizations.",
        date: "Oct 01, 2022",
        source: "https://www.linkedin.com/pulse/implementation-12-factors-app-methodology-aws-cloud-saas-al-mahmud-2c/?trackingId=NJzLhq%2FEQL6oqDXFtOrFMQ%3D%3D",
        tools: ["CloudFormation", "CDK", "AWS SAM"],
      }
    ],
    JavaScript: [
      {
        type: "featured",
        name: "10 Important ES6 Features",
        image: "https://miro.medium.com/max/700/1*DMJiTFRf3zKL0vgyHcmjSQ.png",
        description:
          "After ECMAScript 6 (ES6), JavaScript itself has been a massive changed in the history of the language. In this article, I will show you the interesting things that actually JavaScript makes our life easier than before... Here’s the list of 10 features that, I think, I need to discuss on this blog that might help you understand",
        date: "Nov 3 2020",
        source:
          "https://md-mostafa.medium.com/10-important-es6-features-in-javascript-that-makes-your-life-easy-b609f955910d",
        tools: ["JavaScript", "ES6", "OOJ"],
      },
      {
        type: "regular",
        name: "Most Usages of String methods in JavaScript",
        image: "https://miro.medium.com/max/700/1*KBNF5WAqLOBbiExAp0cc7w.png",
        description:
          "For regular updating and matching with the top languages, JavaScript has ranked as one of the most popular and essential programming languages of all time. Javascript is a high-level language that has curly bracket syntax, dynamic coding style & prototype-based language on object-oriented programming. Today I will discuss most usages methods of String data type in JavaScript. Let’s get started...",
        date: "Nov 2, 2020",
        source:
          "https://md-mostafa.medium.com/most-usages-of-string-methods-in-javascript-f6119ed35749",
        tools: ["JavaScript", "Vanilla", "Programming"],
      },
      {
        type: "featured",
        name: "10 Problems Solving With JS",
        image: "https://miro.medium.com/max/700/1*2h_0iAcPjXX4cWpqAfkxSw.png",
        description:
          "JavaScript consists of a standard library of objects, i.e., Array, Math, and Date, and a core set of programming language elements such as operators, statements, and control structures. JavaScript is an interpreted, lightweight, or just-in-time (JIT) compiled programming language with first-class functions. Every type of problems can be solved through this language.",
        date: "Nov 5, 2020",
        source:
          "https://md-mostafa.medium.com/basic-10-problems-solving-with-javascript-that-should-javascript-developer-practice-97be7a753e9f",
        tools: ["JavaScript", "Programming"],
      },
    ],
    React: [
      {
        type: "featured",
        name: "Top 10 Things In React",
        image: "https://miro.medium.com/max/497/1*KRqsIEKVVHqWd2AxaQpaGA.png",
        description:
          "React is one of the top javascript libraries in the context of frontend development for building user interface or UI components. It's an open-source library and maintained by Facebook. Today I am going to discuss the top 10 important things in React. This list I made is only my preference. It does not mean that there are not any other important parts in it.",
        date: "Nov 4, 2020",
        source:
          "https://md-mostafa.medium.com/top-10-important-things-in-react-that-every-react-developer-must-know-aa2c6d5d3a32",
        tools: ["React", "JavaScript", "SPA"],
      },
    ],
    Node: [

    ],
    Tools: [
      
    ],
  },
}
