export const portfolios = {
  All: {
    Node: [
      {
        type: "featured",
        name: "Creative Agency",
        image: require("./image/creative-agency.gif"),
        description:
          "A MERN stack web based application in which user and admin can use different dashboard panel and all the services records are saved to MongoDB",
        demo: "https://creative-agency2020.web.app/",
        codepen: "",
        source: "https://github.com/bdmostafa/creative-agency-client",
        tools: ["MERN", "Netlify", "Firebase"]
      },
      {
        type: "featured",
        name: "Volunteer Network",
        image: require("./image/volunteer-network.gif"),
        description:
          "Full Stack project - a login authentication is set up using firebase auth. It must be registered to choose an event. Client can choose any event if he/she is logged in.",
        demo: "https://volunteernetwork2020.web.app/",
        codepen: "",
        source: "https://github.com/bdmostafa/volunteer-network-client",
        tools: ["MERN", "Heroku", "Firebase", "Material"]
      },
      {
        type: "featured",
        name: "Travel Guru",
        image: require("./image/travel-guru.gif"),
        description:
          "This is a complete hotel booking site in which you can search area, select date and enjoy your travelings. This project is designed by Material UI and so on",
        demo: "https://travel-guru2020.web.app/",
        codepen: "",
        source: "https://github.com/bdmostafa/travel-guru2020",
        tools: ["React", "Firebase", "Material", "Bootstrap"]
      },
    ],
    Vanilla: [
      {
        type: "featured",
        name: "Task Manager",
        image: require("./image/task-manager.gif"),
        description:
          "An user can make a list of tasks with details and edit, update, delete and make completed. Also counting number option of the tasks is added from three status",
        demo: "https://bdmostafa.github.io/task-manager/",
        codepen: "",
        source: "https://github.com/bdmostafa/task-manager",
        tools: ["Vanilla", "netlify", "ES6", "Webpack"]
      },
      {
        type: "regular",
        name: "Countries Pedia",
        image: require("./image/countries-info.gif"),
        description:
          "This project is built with react, Materiul UI, rest countries api in which you can see all the countries and their details. That's it!",
        demo: "https://countries-pedia.netlify.app/",
        codepen: "",
        source: "https://github.com/bdmostafa/countries-info",
        tools: ["Vanilla", "netlify", "Material", "API"]
      },
      {
        type: "featured",
        name: "Weather App",
        image: require("./image/weather-app.gif"),
        description:
          "Weather App with Feelings, Cloudiness, Wind, Humidity, Pressure, Sunrise, Sunset etc. that you can get from anywhere about 20000 cities in the world ",
        demo: "https://bdmostafa.github.io/weather-app/index.html",
        codepen: "",
        source: "https://github.com/bdmostafa/weather-app",
        tools: ["Vanilla", "ES6", "Material", "Weather-api"]
      },
      {
        type: "featured",
        name: "Harrd Rock Lyrics",
        image: require("./image/hard-rock-lyrics.gif"),
        description:
          "This is a lyrics finder app in which you can search by any letter from anywhere and see the album name, artist name, duration, listen and lyrics as well.",
        demo: "https://bdmostafa.github.io/hard-rock-lyrics/index.html",
        codepen: "",
        source: "https://github.com/bdmostafa/hard-rock-lyrics",
        tools: ["Vanilla", "ES6", "", "lyrics-api"]
      },
      {
        type: "regular",
        name: "Password Generator",
        image: require("./image/password-generator.gif"),
        description:
          "This is a password generator tool in which you create 3 types of password such as weak, medium and strong that will definitely have your online journey be safe and secure.",
        demo: "https://bdmostafa.github.io/password-generator/index.html",
        codepen: "",
        source: "https://github.com/bdmostafa/password-generator",
        tools: ["Vanilla", "CSS3", "HTML5"]
      },
      {
        type: "regular",
        name: "Pin Matcher",
        image: require("./image/pin-matcher.gif"),
        description:
          "This is a pin matcher application in which you can generate a 4 digit PIN, press 0 - 9 digits, and finally match this PIN through submitting. That's It.",
        demo: "https://bdmostafa.github.io/pin-matcher/index.html",
        codepen: "",
        source: "https://github.com/bdmostafa/pin-matcher",
        tools: ["Vanilla", "CSS3", "HTML5"]
      },
      {
        type: "regular",
        name: "Color Checker Game",
        image: require("./image/color-code-game.gif"),
        description:
          "This is a color code checker game where you can check your color code memorizing capacity. We know that there are more 6 lac hexa color code. SO let's play and check it out.",
        demo: "https://bdmostafa.github.io/color-checker-game/",
        codepen: "",
        source: "https://github.com/bdmostafa/color-checker-game",
        tools: ["JavaScript", "CSS", "HTML"]
      },
      {
        type: "regular",
        name: "Random Jokes",
        image: require("./image/random-jokes.gif"),
        description:
          "This is an another JavaScript fun project - A Mostafa's Jokes Generator in which you can find the latest jokes and set up your name in the joke. The APIs are generated from ICNDB.",
        demo: "https://bdmostafa.github.io/jokes-ICNDB-api/",
        codepen: "",
        source: "https://github.com/bdmostafa/jokes-ICNDB-api",
        tools: ["JavaScript", "Jokes-api", "ICNDB"]
      },
    ],
    React: [
      {
        type: "regular",
        name: "Random User Generator",
        image: require("./image/random-user-generator.gif"),
        description:
          "Random user generator is a FREE API for generating placeholder user information. Get profile photos, names, and more. It's like Lorem Ipsum, for people.",
        demo: "https://random-user-generator.netlify.app/",
        codepen: "",
        source: "https://github.com/bdmostafa/random-user-react",
        tools: ["Vanilla", "React", "Users-api"]
      },
      {
        type: "regular",
        name: "Find Random Team Members",
        image: require("./image/find-team-members.gif"),
        description:
          "This is a rendom user/team member generator by using random user api. You can find different team members from anywhere in the world as you want to choose",
        demo: "https://find-team-members.netlify.app/",
        codepen: "",
        source: "https://github.com/bdmostafa/find-team-members",
        tools: ["React", "Git", "API", "Bootstrap"]
      },
      {
        type: "featured",
        name: "Educare Online",
        image: require("./image/educare-online.gif"),
        description:
          "Online educational platform with React - a web based application in which you can choose many courses online and earn a certificate to boost your career",
        demo: "https://educare-online.netlify.app/",
        codepen: "",
        source: "https://github.com/bdmostafa/educare-online",
        tools: ["React", "netlify", "Bootstrap"]
      },
    ],
  }
}
