import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Container } from "../shared"
import "./Blog.css"

import { blogs } from "../../data"

const Blogs = () => {
  const blogCategoriesName = Object.keys(blogs.All)

  const [selectedBlog, setSelectedBlog] = useState("All")

  //   const [allBlog, setAllBlog] = useState([])

  const [handleBlogMap, setHandleBlogMap] = useState([])

  useEffect(() => {
    if (selectedBlog === "All") {
      let allBlog = []
      for (let el of Object.values(blogs.All)) {
        allBlog.push(...el)
      }
      setHandleBlogMap(allBlog)
    } else {
      setHandleBlogMap(blogs.All[selectedBlog])
    }
  }, [selectedBlog])

  // console.log(...Object.values(blogs.All))

  return (
    <Container>
      <div className="blogs" style={{ marginBottom: "100px" }}>
        <ul className="blog-nav mouseEvents">
          <li
            onClick={() => setSelectedBlog("All")}
            className={selectedBlog === "All" ? "active" : ""}
          >
            All
          </li>
          {blogCategoriesName.map(name => (
            <li
              onClick={() => setSelectedBlog(name)}
              className={
                name === "All"
                  ? "active"
                  : name === selectedBlog
                  ? "active"
                  : ""
              }
              key={name}
            >
              {name}
            </li>
          ))}
        </ul>

        <div className="blog-items">
          {handleBlogMap &&
            handleBlogMap.map((blog, idx) => (
              <div key={idx} className="blog">
                <a
                  className="blog-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={blog.source && blog.source}
                >
                  <div className="blog-img mouseEvents">
                    <img alt={blog.name} src={blog.image} />
                  </div>
                  <div className="details">
                    <h4>{blog.name}</h4>
                    <p>{blog.description}</p>
                    <div className="links">
                      <ul
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {blog.date && <li>{blog.date}</li>}
                        {blog.source && (
                          <li>
                            <a
                              style={{ textDecoration: "none" }}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={blog.source}
                            >
                              Read More...
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                    <ul className="blog-tools">
                      {blog.tools &&
                        blog.tools.map(tool => <li key={tool}>{tool}</li>)}
                    </ul>
                  </div>
                </a>
              </div>
            ))}
        </div>
      </div>
    </Container>
  )
}

export { Blogs }
