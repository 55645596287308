import React from "react"

import { Layout, SEO } from "../components/shared"
import { Blogs } from "../components/Blog/Blogs"

const BlogPage = (props) => (
  <>
    <Layout locationPath={props.location.pathname}>
        <SEO title="Mostafa's Blog | Medium | Blog | Technology | Programming | Web Programming | mdmostafa.com" />
        <Blogs />
      </Layout>
  </>
)

export default BlogPage;
