export const skills = {
  Frontend: [
    { name: "React", percent: 90 },
    { name: "Gatsby", percent: 50 },
    { name: "React-Bootstrap", percent: 90 },
    { name: "SASS", percent: 70 },
    { name: "Redux", percent: 60 },   
    { name: "Material UI", percent: 80 },   
  ],
  Backend: [
    { name: "Node JS", percent: 70 },
    { name: "Express JS", percent: 90 },
    { name: "MongoDB", percent: 80 },
    { name: "MySQL", percent: 90 },
    { name: "GraphQL", percent: 80 },
    { name: "Monggose", percent: 80 },
  ],
  AWS: [
    { name: "Lambda", percent: 85 },
    { name: "Amplify", percent: 90 },
    { name: "DynamoDB", percent: 80 },
    { name: "WA Framework Review", percent: 90 },
    { name: "Code Pipeline", percent: 80 },
    { name: "Serverless", percent: 80 },
  ],
  Others: [
    { name: "Vanilla JS", percent: 90 },
    { name: "C++", percent: 75 },
    { name: "PHP", percent: 75 },
    { name: "HTML", percent: 90 }, 
    { name: "CSS", percent: 90 },
  ],
  Tools: [
    { name: "Git", percent: 90 },
    { name: "Jest", percent: 50 },
    { name: "Mocha", percent: 50 },
    { name: "Docker", percent: 50 },
    { name: "VS Code", percent: 90 },
    { name: "Chrome Dev Tool", percent: 80 },
  ],
}
